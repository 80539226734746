<script>
import { formatDollar } from '/~/utils/format/money'

export default {
  name: 'profile-donut-chart',
  props: {
    chartValues: {
      type: Array,
      default: () => [],
    },
    dimension: {
      type: Number,
      default: 280,
    },
    strokeWidth: {
      type: Number,
      default: 60,
    },
    colors: {
      type: Array,
      default: () => ['#F04360', '#FC9A07', '#06B6D4', '#6F90EF'],
    },
  },
  setup() {
    return {
      formatDollar,
    }
  },
  computed: {
    hasChartValue() {
      return this.chartValues && this.chartValues.length > 0
    },
    sortedValues() {
      const emptyObject = {
        name: 'Empty',
        color: 'var(--color-primary)',
        saving: 0,
      }
      const chartData =
        this.chartValues.length > 0 ? this.chartValues : [emptyObject]
      const items = [...chartData]

      return items.sort((a, b) => b.saving - a.saving)
    },
    combinedValues() {
      let data = []
      const initialCategories = this.sortedValues.slice(0, 3)
      const remainingCategories = this.sortedValues.slice(3)

      const initValue = 0
      const totalOtherValues = remainingCategories.reduce((acc, val) => {
        return acc + val.saving
      }, initValue)

      const otherCategory = {
        name: 'Other',
        slug: 'other',
        saving: totalOtherValues,
      }

      if (remainingCategories.length > 0) {
        data = [...initialCategories, otherCategory]
      } else {
        data = [...initialCategories]
      }

      return data
    },
    // adjust the circumference to add small white gaps
    adjustedCircumference() {
      return this.circumference - 0
    },
    cx() {
      return this.dimension / 2
    },
    cy() {
      return this.dimension / 2
    },
    radius() {
      return (this.dimension - this.strokeWidth) / 2
    },
    circleBindings() {
      return {
        cx: this.cx,
        cy: this.cy,
        r: this.radius,
        'stroke-width': this.strokeWidth,
      }
    },
    svgBindings() {
      return {
        height: this.dimension,
        width: this.dimension,
        viewBox: `0 0 ${this.dimension} ${this.dimension}`,
      }
    },
    circumference() {
      return 2 * Math.PI * this.radius
    },
    totalValue() {
      const initVal = 0

      return this.sortedValues.reduce((acc, val) => {
        return acc + val.saving
      }, initVal)
    },
    refinedChartData() {
      const chartArray = []
      let angleOffset = -90 // start at -90deg so that the largest segment is perpendicular to top

      this.combinedValues.forEach((item, index) => {
        const { x, y } = this.calculateTextCoords(item.saving, angleOffset)
        const percentage = item.saving / this.totalValue
        const percentOnHundred = Math.round(percentage * 100)

        const data = {
          name: item.name,
          saving: item.saving,
          contentX: x,
          contentY: y,
          degrees: angleOffset,
          transform: `rotate(${angleOffset}, ${this.cx}, ${this.cy})`,
          strokeOffset: this.circumference - percentage * this.circumference,
          color: item.color ? item.color : this.colors[index],
          percentage: `${percentOnHundred}%`,
          segmentBigEnough: percentOnHundred > 5,
        }

        angleOffset = percentage * 360 + angleOffset
        chartArray.push(data)
      })

      return chartArray
    },
  },
  methods: {
    calculateTextCoords(dataVal, angleOffset) {
      // t must be radians
      // x(t) = r cos(t) + j
      // y(t) = r sin(t) + j

      const angle = (this.dataPercentage(dataVal) * 360) / 2 + angleOffset
      const radians = angle * (Math.PI / 180)

      const textCoords = {
        x: this.radius * Math.cos(radians) + this.cx,
        y: this.radius * Math.sin(radians) + this.cy,
      }

      return textCoords
    },
    dataPercentage(dataVal) {
      return dataVal / this.totalValue
    },
  },
}
</script>

<template>
  <div>
    <div class="mb-11 flex w-full items-center justify-center">
      <svg v-bind="svgBindings">
        <g v-for="(item, index) in refinedChartData" :key="`chart-${index}`">
          <circle
            v-bind="circleBindings"
            :stroke="item.color"
            :stroke-dasharray="adjustedCircumference"
            :stroke-dashoffset="item.strokeOffset"
            :transform="item.transform"
            fill="transparent"
          />

          <text
            v-if="item.segmentBigEnough"
            text-anchor="middle"
            dy="3px"
            fill="white"
            :x="item.contentX"
            :y="item.contentY"
          >
            {{ item.percentage }}
          </text>
        </g>
      </svg>
    </div>
    <div v-if="hasChartValue" class="rounded-md border p-5">
      <div
        v-for="(cat, index) in refinedChartData"
        :key="`${cat.name}-${index}`"
        class="flex items-center justify-between"
        :class="index === refinedChartData.length - 1 ? 'mb-0' : 'mb-4'"
      >
        <span
          class="mr-[15px] block h-[30px] w-[30px] rounded-full"
          :style="`background-color:${cat.color}`"
        />
        <div class="mr-[15px] flex-1 truncate font-bold">
          {{ cat.name }}
        </div>
        <div>
          <div class="font-bold">
            {{ formatDollar(cat.saving) }}
          </div>
          <div class="text-right text-eonx-neutral-600">
            {{ cat.percentage }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center text-neutral-400">
      No transactions available.
    </div>
  </div>
</template>
